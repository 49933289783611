<template>
  <div class="products">
    <h1>Products Page</h1>      
  </div>
</template>

<script>


export default {
  name: 'Products',
  components: {
    
  }
}
</script>
